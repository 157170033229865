import React, { useState } from "react";

import '../../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Faq.css';
import '../../../Colors.css'


function Faq({question, answer}){
    const [isVisible, setVisibility] = useState(false)
    const [rotateArrow, setRotateArrow]=useState(false)

    function showAnswer(){
        setVisibility(!isVisible)
        setRotateArrow(!rotateArrow)
    }

    return <>
        <div 
        className="outerQuestionContainer">
            <div
                className="questionContainer"
                onClick={showAnswer}>
                <p className="questionFontStyle"><strong>{question}</strong></p>
                <p 
                className={`questionFontStyle questionArrow ${rotateArrow ? 'questionArrowRotate' : ''}`}
                    >&#9660;</p> 
            </div>
            <div className={`answerContainer ${isVisible ? 'answerContainerShow' : ''}`}>
                <p className="answerFontStyle" dangerouslySetInnerHTML={{__html: answer}}></p>
            </div>
        </div>
    </>
}
export default Faq