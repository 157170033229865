import React from 'react';

import './Modal.css';

function Modal(props){
    function externalContainerTouchDetected(e){
        if(e.target.classList.contains('innerContainer')){
            if(props.isDismissable){
                props.setShowModal(false)
            }
        }
    }

    return <>
        <div 
        onClick={externalContainerTouchDetected}
        className={`externalContainer ${props.show ? 'showModal' : '' }`}>
                <div className="innerContainer">
                    {props.children}
            </div>
        </div>
    </>
}

export default Modal