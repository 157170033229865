import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './reset.css';
import './index.css';
import './Colors.css';
import './Fonts.css';
import './fonts/gothree.ttf'
import './fonts/gotu.ttf'

import Foodtruck from './components_main/FoodtruckPage'
import Restaurant from './components_main/RestaurantPage'
import Home from './components_main/home/Home'
import { LocationContextProvider } from './contexts/navigation/LocationContext'


require('dotenv').config()

function App() {
  const history = useNavigate();
  React.useEffect(() => {
    console.log(history);
  }, []);


  return <LocationContextProvider>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="foodtruck" element={<Foodtruck />} />
      <Route path="restaurant" element={<Restaurant />} />
    </Routes>
  </LocationContextProvider>
  ;
}

export default App;