import React, { useState, useEffect,useRef } from "react";
import Flatpickr from "react-flatpickr";

import RadioButton from './components/RadioButton'

import Helpers from "../../helpers/PublicHelper";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Colors.css'
import './Catering.css';

// import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/themes/dark.css";

require('dotenv').config()

var apiUrl = ""
if(process.env.NODE_ENV===process.env.REACT_APP_DEV_MODE){
    apiUrl = process.env.REACT_APP_DEV_REST_API_SERVER
}else{
    apiUrl = process.env.REACT_APP_PROD_REST_API_SERVER
}

function Catering({showCatering,setShowCatering,setShowAlert}){  
    const fp1 = useRef(null)
    const fp2 = useRef(null)
    const fp3 = useRef(null)
    const [cateringData,setCateringData]=useState({
        name:"",
        phoneNumber:"",
        email:"",
        dateRequested:"",
        eventDescription:"",
        eventAddress:"",
        evenStartTime:"",
        serviceStartTime:"",
        guestNumber:"",
        services: {
            bookTruck:false,
            boxedMeals:false,
            fullCatering:false,
            selfServiceCatering:false,
            bulkOrder:false,
            companyEvent:false
        }
    })
    const [radioValue,setRadioValue]=useState(null)
    const [sendMessage,setSendMessage]=useState(false)
    /** FETCH DATA **/
    function saveNewContact(e) {
        e.preventDefault()
        var alertObject = {
            sendAlert:false,
            noServiceChosen:true,
            msg:"Before sending your message, you must fill out the following fields: \n"
        }
        function modifyAlertObject(msg){
            alertObject.sendAlert = true
            alertObject.msg = `${alertObject.msg} ${msg}\n`
        }
        for(let e in cateringData){
            if(cateringData[e]===""){ 
                if(e==="name"){ modifyAlertObject("Name")}
                if(e==="phoneNumber"){ modifyAlertObject("Phone Number")}
                if(e==="email"){ modifyAlertObject("Email")}
                if(e==="dateRequested"){ modifyAlertObject("Date Requested")}
                if(e==="eventDescription"){ modifyAlertObject("Event Description")}
                if(e==="eventAddress"){ modifyAlertObject("Address")}
                if(e==="evenStartTime"){ modifyAlertObject("Start Time of Event")}
                if(e==="serviceStartTime"){ modifyAlertObject("Service Start Time")}
                if(e==="guestNumber"){ modifyAlertObject("Number of Guests")}
            }
            if(e==="guestNumber"){
                if(parseInt(cateringData[e])===0){modifyAlertObject("Number of Guests cannot be 0.") }
            }
        }
        if(!Helpers.validateEmail(cateringData.email)){
            modifyAlertObject("You must inform a valid email address.")
        }
        if(!Helpers.validatePhoneNumber(cateringData.phoneNumber)){
            modifyAlertObject("You must inform a valid phone number.")
        }
        for(let e in cateringData.services){
            if(cateringData.services[e]===true){
                alertObject.noServiceChosen = false
            }
        }
        if(alertObject.noServiceChosen){
            modifyAlertObject("You must select one of the Services Expecations")
        }
        if(alertObject.sendAlert){
            setShowAlert({show:true,msg:alertObject.msg,classes:"alert-danger"})
        }
        if(!alertObject.sendAlert){
            setSendMessage(true)
        }
    }
    useEffect(()=>{
        if(!sendMessage){return}
        var description = "=== MESSAGE BEGINS ====\n"
        var name = ""
        var email = ""
        var phoneNumber = ""
        for(let e in cateringData){
            if(e==="name"){ name = cateringData[e]}
            if(e==="phoneNumber"){ phoneNumber= cateringData[e] }
            if(e==="email"){ email= cateringData[e]}
            if(e==="dateRequested"){ description = `${description} __REQUESTED DATE: ${cateringData[e]}.\n`}
            if(e==="eventDescription"){ description = `${description} __EVENT DESCRIPTION: ${cateringData[e]}.\n`}
            if(e==="eventAddress"){ description = `${description} __EVENT ADDRESS: ${cateringData[e]}.\n`}
            if(e==="evenStartTime"){ description = `${description} __EVENT START TIME: ${cateringData[e]}.\n`}
            if(e==="serviceStartTime"){ description = `${description} __SERVICE START TIME: ${cateringData[e]}.\n`}
            if(e==="guestNumber"){ description = `${description} __NUMBER OF GUESTS: ${cateringData[e]}.\n`}
        }
        description = `${description} __==SERVICE EXPECTATIONS\n`
        for(let e in cateringData.services){
            if(cateringData.services[e]){
                if(e==="bookTruck"){ description = `${description} Book food truck: yes\n`}
                if(e==="boxedMeals"){ description = `${description} Boxed meals: yes\n`}
                if(e==="fullCatering"){ description = `${description} Full catering: yes\n`}
                if(e==="selfServiceCatering"){ description = `${description} Self service catering: yes\n`}
                if(e==="bulkOrder"){ description = `${description} Bulk order: yes\n`}
                if(e==="companyEvent"){ description = `${description} Company event: yes\n`}
            }
        }
        description = `${description} === MESSAGE ENDS ====\n`
        fetch(`${apiUrl}/api/catoring/saveContact`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Request-Headers': 'Origin,Content-Type,Authorization'
            },
            body: JSON.stringify({email:email,name:name,phoneNumber:phoneNumber,orderDescription:description})   })
        .then(a1 => {return a1.json()})
        .then(response => {
            console.log(response)
            if(!response.hasErrors){
                setShowAlert({show:true,msg:response.msg,classes:"alert-success"})
                hideCatering()
            }else{
                setShowAlert({show:true,msg:response.msg,classes:"alert-danger"})
                setSendMessage(false)    
            }
        })
        .catch(err => {
            console.log(err)
            setShowAlert({show:true,msg:"Not possible to send your message at this time. Please try again later.",classes:"alert-danger"})
            setSendMessage(false)
        })
    },[sendMessage])
    function dismissCatering(e){
        if(e.target.classList.contains('modalCatering')){
            hideCatering()
        }
    }
    function hideCatering(){
        setCateringData({
            name:"",
            phoneNumber:"",
            email:"",
            dateRequested:"",
            eventDescription:"",
            eventAddress:"",
            evenStartTime:"",
            serviceStartTime:"",
            guestNumber:"",
            services: {
                bookTruck:false,
                boxedMeals:false,
                fullCatering:false,
                selfServiceCatering:false,
                bulkOrder:false,
                companyEvent:false
            }
        })
        unselectServices()
        clearAllInputs()
        setShowCatering(false)
    }
    function updateDataOnInput(e,dateRequested=false,eventStartTime=false,serviceStartTime=false,value){
        var o = cateringData
        if(e===null){
            if(dateRequested){
                o.dateRequested = value
            }
            if(eventStartTime){
                o.evenStartTime = value
            }
            if(serviceStartTime){
                o.serviceStartTime = value
            }
        }else{
            if(e.target.id==="name"){
                o.name = e.target.value
            }
            if(e.target.id==="phoneNumber"){
                //TO DO VALIDATE
                o.phoneNumber = Helpers.noPhoneNumberMask(e.target.value)
                e.target.value = Helpers.phoneNumberMask(o.phoneNumber)
            }
            if(e.target.id==="email"){
                //TO DO VALIDATE
                o.email = e.target.value
            }
            if(e.target.id==="eventDescription"){
                o.eventDescription = e.target.value
            }
            if(e.target.id==="address"){
                o.eventAddress = e.target.value
            }
            if(e.target.id==="numberGuests"){
                o.guestNumber = e.target.value
            }
        }
        setCateringData(o)
    }
    function clearAllInputs(){
        for(let e of document.getElementsByTagName('input')){
            e.value = ""
        }
        for(let e of document.getElementsByTagName('textarea')){
            e.value = ""
        }
        fp1.current.flatpickr.clear()
        fp2.current.flatpickr.clear()
        fp3.current.flatpickr.clear()
    }
    function unselectServices(){
        var o = cateringData
        for(let e in o.services){
            o.services[e] = false
        }
        setRadioValue(null)
        setCateringData(o)
    }
    function radioButtonUpdate(e){
        var o = cateringData
        unselectServices()
        if(e.target.id==="bookTruck"){
            o.services.bookTruck = true
        }
        if(e.target.id==="boxedMeals"){
            o.services.boxedMeals = true
        }
        if(e.target.id==="fullCatering"){
            o.services.fullCatering = true
        }
        if(e.target.id==="selfServiceCatering"){
            o.services.selfServiceCatering = true
        }
        if(e.target.id==="bulkOrder"){
            o.services.bulkOrder = true
        }
        if(e.target.id==="companyEvent"){
            o.services.companyEvent = true
        }
        setRadioValue(e.target.id)
        setCateringData(o)
    }
    return <>
        <div 
        className="modalCatering h-100 w-100" style={
        {visibility: showCatering ? 'visible' : 'hidden',
        opacity: showCatering ? 1 : 0}}
        onClick={dismissCatering}>
            <div 
            className="modalCateringInnerWrapper">
                <div 
                className={`calendarCloseButton`}
                onClick={hideCatering}>X</div>
                    <div className="cateringOuterCard ">
                        <div className="cateringInnerTexFieldCards">
                            <div className="cateringInnerCardWrapper">
                                <div className="texfieldCateringLabel">Contact Information</div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Name: </p> 
                                    <input 
                                    id={"name"} type={"text"}
                                    onInput={updateDataOnInput}
                                    maxLength={100}/>
                                </div>

                                <div className="texfieldInnerCateringInforBox">
                                    <p>Phone Number: </p> 
                                    <input 
                                    id={"phoneNumber"} type={"text"}
                                    onInput={updateDataOnInput}
                                    maxLength={14}/>
                                </div>

                                <div className="texfieldInnerCateringInforBox">
                                    <p>Email: </p> 
                                    <input 
                                    id={"email"} type={"text"}
                                    onInput={updateDataOnInput}
                                    maxLength={100}/>
                                </div>
                            </div>
                        </div>

                        <div className="cateringInnerTexFieldCards">
                            <div className="cateringInnerCardWrapper">    
                                <div className="texfieldCateringLabel">Catering/Book Our Truck Information</div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Date Requested: </p> 
                                    <Flatpickr
                                        // data
                                        ref={fp1}
                                        options={{ 
                                            minDate: new Date(),
                                            enableTime:false
                                        }}
                                        onChange={([date],datStr,instance) => {
                                            updateDataOnInput(null,true,false,false,datStr)
                                        }}
                                    />
                                </div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Event Description: </p> 
                                    <textarea 
                                    id={"eventDescription"} type={"text"}
                                    onInput={updateDataOnInput}>
                                    </textarea>
                                </div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Address: </p> 
                                    <input 
                                    id={"address"} type={"text"}
                                    onInput={updateDataOnInput}
                                    maxLength={100}/>
                                </div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Start Time of Event: </p> 
                                    <Flatpickr options={{
                                        noCalendar:true,
                                        enableTime:true,
                                        dateFormat:'h:i K',
                                        minTime: "09:00",
                                        maxTime: "00:00"
                                    }}
                                    ref={fp2}
                                    onChange={([date],datStr,instance) => {
                                        console.log(datStr)
                                        updateDataOnInput(null,false,true,false,datStr)
                                    }}
                                    />
                                </div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Service Start Time: </p> 
                                    <Flatpickr options={{
                                        noCalendar:true,
                                        enableTime:true,
                                        dateFormat:'h:i K',
                                        minTime: "09:00",
                                        maxTime: "00:00"
                                    }}
                                    ref={fp3}
                                    onChange={([date],datStr,instance) => {
                                        console.log(datStr)
                                        updateDataOnInput(null,false,false,true,datStr)
                                    }}
                                    />
                                </div>
                                <div className="texfieldInnerCateringInforBox">
                                    <p>Number of Guests: </p> 
                                    <input 
                                    id={"numberGuests"} type={"number"}
                                    onInput={updateDataOnInput}
                                    min={0}/>
                                </div>
                            </div>
                        </div>

                        <div className="cateringInnerTexFieldCards">
                            <div className="cateringInnerCardWrapper">
                                <div className="texfieldCateringLabel">Service Expectations</div>
                                <div className="texfieldInnerCateringInforRadio">
                                    <RadioButton key={1} changed={radioButtonUpdate} id={"bookTruck"} isSelected={radioValue==="bookTruck"} label={"Book Our Truck"} value={"bookTruck"} />
                                    <RadioButton key={2} changed={radioButtonUpdate} id={"boxedMeals"} isSelected={radioValue==="boxedMeals"} label={"Boxed Meals"} value={"boxedMeals"} />
                                    <RadioButton key={3} changed={radioButtonUpdate} id={"fullCatering"} isSelected={radioValue==="fullCatering"} label={"Catering with Full Service (Includes a Fully Serviced Buffet Line Setup)  "} value={"fullCatering"} />
                                    <RadioButton key={4} changed={radioButtonUpdate} id={"selfServiceCatering"} isSelected={radioValue==="selfServiceCatering"} label={"Self Service Catering (Rented Chaffing Dishes Included)"} value={"selfServiceCatering"} />
                                    <RadioButton key={5} changed={radioButtonUpdate} id={"bulkOrder"} isSelected={radioValue==="bulkOrder"} label={"Bulk Order (Pans of Food for Pick Up or Delivery)"} value={"bulkOrder"} />
                                    <RadioButton key={6} changed={radioButtonUpdate} id={"companyEvent"} isSelected={radioValue==="companyEvent"} label={"Company Sponsored Event/Luncheon"} value={"companyEvent"} />
                                </div>
                                <div className="footNoteCateringCards">We do not carry vegetarian or vegan options on our regular menu, if these options are desired we will work to customize the menu to your needs the best we can!</div>
                            </div>
                        </div>
                        <button
                            className="cateringSendMessageButton"
                            onClick={saveNewContact}>
                            Send Message
                        </button>
                    </div>
            </div>
        </div>
    </>
}

export default Catering