import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom";

import Entrance from '../components_restaurant/1_entrance/Entrance'
import NavContainer from '../components_restaurant/2_navbar/NavContainer'
import MainContainer from '../components_restaurant/3_mainContainer/MainContainer';
import Catering from '..//components_restaurant/10_catering/Catering'

import { LocationContext } from '../contexts/navigation/LocationContext'


function Foodtruck() {
  const [navBarHidden, setNavBarHidden]=useState(true)
  const [showAlert, setShowAlert]=useState({show:false,msg:"",classes:null})
  const [showCatering,setShowCatering]=useState(false)
  const {landeOnHomePage} = useContext(LocationContext)
  const navigate = useNavigate()
  /** check initial navigation */
  useEffect(()=>{
    if(!landeOnHomePage){
      navigate(`/`)
    }
  },[landeOnHomePage])

  function dismissAlert(){
    setShowAlert({show:false,msg:"",classes:null})
  }
  /* show alert timer */
  useEffect(()=>{
    var timer1 
    if(!showAlert.show){return}
    function clear(){
      clearTimeout(timer1)
      dismissAlert()
    }
    timer1 = setTimeout(() => {
      clear()
    }, 10000);
  },[showAlert])
  /*change body properties to prevent scrolling */
  if(showCatering){
    document.getElementsByTagName('body')[0].classList.add('setBodyPositionToFixed')
  }else{
    document.getElementsByTagName('body')[0].classList.remove('setBodyPositionToFixed')
  }
  return <>
     <div 
        id="mainDiv" 
        className=" h-100" >
        <div className={`alert ${showAlert.classes === null ? 'alert-dark' : showAlert.classes } fade ${showAlert.show ? 'show' : ''}`} role="alert" style={{zIndex: showAlert.show ? 999 : -9}}>
          <p>{showAlert.msg}</p>
          <button type="button" className="close alertClose" data-dismiss="alert"  aria-label="Close"
          onClick={dismissAlert}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <NavContainer navBarHidden={navBarHidden}/>
        <Catering showCatering={showCatering} setShowCatering={setShowCatering} setShowAlert={setShowAlert} />
        <Entrance setNavBarHidden={setNavBarHidden} setShowCatering={setShowCatering} />
        <MainContainer navBarHidden={navBarHidden} />
    </div>  
  </>
  ;
}

export default Foodtruck;