import React, { useState } from "react";

import '../../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Rows.css';
import '../../../Colors.css'

import "../../../fonts/gothree.ttf"

import Placeholder from '../../../resources/imgs/placeholder.png'

function Row({picture, dishName, id, ingredients, price}){
    const [contentVisible, setContentVisibility]=useState(false)
    function expandButtonClicked(e){
        setContentVisibility(!contentVisible)
    }

    return <>
        <div className="mostOuterDishContainer col-1"></div>
        <div
        className={`mostOuterDishContainer col-10`}>
            <div 
            className="dishOuterContainer"
            onClick={expandButtonClicked}>
                <div 
                className="dishOuterContainerName menuStyle marginLeft10"
                onClick={expandButtonClicked}>{dishName}</div>
                <button
                className={`buttonDish ${contentVisible ? 'buttonRotate' : ''} dishOuterContainerButton innerColCursorPointer  marginRight10  text-center`}
                onClick={expandButtonClicked}>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div 
                className={`dishInnerContainer dishInnerContainerHidden ${contentVisible ? 'dishInnerContainerShow' : ''}`}>
                    <div className="row dishRowContainer">
                        <img 
                            alt=""
                            className={`col-12 col-sm-3 dishContainerImage`}
                            src={picture !== "" ? require(`../../../resources/imgs/${picture}`) : Placeholder} ></img>
                        <div 
                        className="col-12 col-sm-7 dishContainerText">
                            <p 
                            className="dishContainerTextText">{ingredients}</p>
                        </div>
                    </div>
            </div>
        </div>
        <div className="mostOuterDishContainer col-1"></div>
    </>
}

export default Row