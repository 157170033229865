import React, { useEffect, useRef } from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Entrance.css';


function Entrance({setNavBarHidden,setShowCalendarMod,setShowCatering}){
    /*hiding or unhiding NAVBAR */
    const containerRef = useRef(null)
    const cb = (entries) =>{
        console.log(entries)
        const [entry] = entries
        console.log(entry)
        if(!entry.isIntersecting){
            setNavBarHidden(false)
        }else{
            setNavBarHidden(true)
        }
    }
    const options = {
        threshold:0.2
    }
    useEffect(()=>{
        const observer = new IntersectionObserver(cb, options)
        if(containerRef.current) observer.observe(containerRef.current)

        return ()=> {
            if(containerRef.current) observer.unobserve(containerRef.current)
        }

    },[containerRef,options])
    /* SHOW CALENDAR SCREEN */
    const showCalendarModalWindow = ()=>{
        setShowCalendarMod(true)
    }
    const showCatering = ()=>{
        setShowCatering(true)
    }   

    return <>
        <div className="w-100 h-100 ">
            <div
                ref={containerRef} 
                className="slideFromTopForPageEntrance wholeHeight wholeWidth backgroundPicture displayModeFlex overflowHidden w-100"> 
                <div
                    className="wholeHeight_1 wholeWidth_1 backgroundPicture_1 positionAbsolute_1">
                        <div
                            className="positionRelative_2 alignedTopRight_2 backgroundPicture_2 height_2 width_2">
                        </div>
                        <div className="calendarFoodTruckText">The food truck</div>
                </div>
            </div>
            <div className="buttonGroup">
                <button
                className="buttonStyle slideFromTopForPageEntrance"
                onClick={showCalendarModalWindow}>Order
                </button>
                <button
                className="buttonStyle buttonStyleCattering slideFromTopForPageEntrance"
                onClick={showCatering}>Book Our Truck/Catering
                </button>
            </div>
        </div>
    </>
}

export default Entrance