import React, { useState } from "react";

import '../../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../Colors.css'
import './EventDetail.css';

import Helpers from '../../../helpers/PublicHelper'

const foodTruckLink = "https://kungfubbqltd.getbento.com/online-ordering/kungfubbqfoodtruck/menu/order-settings?fbclid=IwAR1m7D2TRqqc2MRRvuj0d7URRgYeW_G19VomV4VoAXVn6QmUtkuciJnHi9o"

function EventDetail({event,index,onlyEvent}){
    const [showEvent,setShowEvent] = useState(false)
    const e = event
    function showHideEventDetails(e){
        if(onlyEvent===1){return}
        setShowEvent(!showEvent)
    }
    var dishesResults = {preOrders:[],fifo:[]}
    if(e.eventOnly===0){
        let preOrders=[]
        let fifo=[]
        e.dishes.forEach((d,i) => {
            if(d.dishFifo===1){
                fifo.push(<p>{i+1}- ${d.dishPrice} {d.dishName}</p>)
            }else{
                preOrders.push(<p>{i+1}- ${d.dishPrice} {d.dishName}</p>)
            }
        })
        dishesResults.preOrders = preOrders
        dishesResults.fifo = fifo
    }
    return <>
        <div key={`index_${index}`} id={`index_${index}`}
            className={`eventsContainerWrapper`}>
                <div className="innerEventsTitleContainer"
                onClick={showHideEventDetails}>
                    <p className="eventsContainerWrapperTitle">{onlyEvent ? "Only event of the day" : `Event ${index} >> ${e.eventOnly === 1 ? 'online order' : ' app pre-order'}`}</p>
                    {!onlyEvent ?
                        <p className={`detailArrow ${showEvent ? 'detailArrowRotate' : ''}`}>&#9660;</p>
                    : <></>}
                </div>
                <div id={`innerBox_${index}`} className={`innerEventsContainer ${onlyEvent ? "" : showEvent ? '' : `innerEventsContainerHidden`}`}>
                    <div
                    className="informationBoxes">
                        <p className="informationTitles">Event:&nbsp;</p>
                        <p className="informationEvents"><strong>{Helpers.returnMonth(parseInt(String(e.cookingDate).split(" ")[0].split('-')[1]-1))}</strong> <strong>{String(e.cookingDate).split(" ")[0].split('-')[2]}</strong> from <strong>{String(e.cookingDateAmPm).split(" ")[1]}</strong> to <strong>{String(e.cookingDateEndAmPm).split(" ")[1]}</strong> 
                        </p>
                    </div>

                    <div
                    className="informationBoxes">
                        <p className="informationTitles">Venue:&nbsp;</p>
                        <p className="informationEvents"><strong>{Helpers.checkNullString(e.venue) === "" ? "Not informed" : e.venue}</strong> </p>
                    </div>

                    <div
                    className="informationBoxes">
                        <p className="informationTitles">Address:&nbsp;</p>
                        <p className="informationEvents">
                            <strong>{Helpers.checkNullString(e.street)}</strong> 
                            {Helpers.checkNullString(e.street)==="" ? '' : '\n'}
                            <strong>{Helpers.checkNullString(e.city)}</strong> 
                            {Helpers.checkNullString(e.city)==="" ? '' : '\n'}
                            <strong>{Helpers.checkNullString(e.state)}</strong> 
                            {Helpers.checkNullString(e.state)==="" ? '' : '\n'}
                            </p>
                    </div>
                    <div
                    className="informationBoxes menuVariation">
                        <p className="informationTitles">Menu:&nbsp;</p>
                        <p className="informationEvents menuVariationText">
                            {e.eventOnly===1 ?
                                <>
                                    <p><strong>FIRST COME FIRST SERVED</strong></p>
                                    {e.dishes.map((d,i)=>{
                                        return <p>{i+1}- ${d.dishPrice} {d.dishName}</p>
                                    })}
                                </>
                            :
                                <>
                                <p><strong>ONLY THROUGH APP PRE-ORDER</strong></p>
                                {dishesResults.preOrders}
                                <p><strong>FIRST COME FIRST SERVED</strong></p>
                                {dishesResults.fifo}
                                </>
                            }
                            </p>
                    </div>

                    <div
                    className="informationBoxes mapVariation">
                        <p className="informationTitles">Map:&nbsp;</p>
                        <iframe
                        title={"foodtruck"}
                        className="googleMaps"
                        style={{border:0}}
                        loading="lazy"
                        allowfullscreen
                        referrerpolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBNGjX8WiKGH8J1dTDHrYvCBIuz2EtqTbo&q=${e.lat},${e.lng}&zoom=15`}>
                        </iframe>
                    </div>
                    {e.eventOnly===1 ? 
                        <>
                            <div
                            className="informationBoxes informationBoxesVar1 menuVariation">
                                Served first come first served or ...
                            </div>
                            <div
                            className="informationBoxes informationBoxesVar2 menuVariation">
                                <a href={foodTruckLink} target={"_blank"}>Order Online</a>
                            </div>
                        </>
                        :
                        <>
                            <div
                            className="informationBoxes informationBoxesVar3 menuVariation">
                                <p>Only available on our mobile apps.</p>
                                <div className="appBadges">

                                <a href='https://apps.apple.com/us/app/kungfubbq/id1596682241?itsct=apps_box_badge&amp;itscg=30200' target={"_blank"} style={{display: "inline-block", overflow: "hidden", borderRadius: "13px"}}>
                                    <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1643587200&h=d91586cb90d9be35530962de8a312af9" alt="Download on the App Store" style={{borderRadius: "13px", width: "250px", height: "44px"}}></img>
                                </a>

                                <a href='https://play.google.com/store/apps/details?id=me.dgmieth.kungfubbq&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'target={"_blank"}>
                                    <img className="storeBadgesImages" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'></img>
                                </a>
                                </div>
                            </div>
                        </>
                        }
                </div>
            </div>
    </>
}   

export default EventDetail