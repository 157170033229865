import React from "react";

import '../../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Testimony.css';
import '../../../Colors.css'

import "../../../fonts/gothree.ttf"


function Testimony({picture,name,date,text,link}){
    var init = "profile1"
    var pic = require(`./${init}.png`)
    if(picture !== ""){
        pic = require(`../../../resources/imgs/${picture}`) 
    }
    return <>
        <div 
        className="outerTestimonySlideContainer">
            <div className="testimonyProfileImgContainer">
                <img src={pic} alt=""/>
            </div>
            <p className="testimonyUserName"><strong>{name}</strong></p>
            <div className="testimonyImportantTextPart">
                <p className="testimonyImportantTextStyle">{date}</p>
                <p className="testimonyImportantTextStyle">{text}</p>
            </div>
            <a 
            className="testimonyImportantTextAnchor"
            href={"https://www.facebook.com/groups/205409104997206/"} target={"_blank"}>As seen in KungFuBBQ Ltd Facebook group</a>
        </div>
    </>
}

export default Testimony