import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainContainer.css';
import '../../Colors.css'

import SectionTitle from "../4_sectionTitles/SectionTitle";
import Menu from '../5_menu/Menu';
import About from '../6_aboutUs/About';
import Faqs from '../7_faqs/Faqs'
import Testimonials from "../8_testimonials/Testimonials";
import Footer from "../9_footer/Footer";

function MainContainer({navBarHidden}){

    return <>
       <div 
            id="mainContent" 
            className="w-100">
            <SectionTitle picture={''} titleName={"Menu"} navBarHidden={navBarHidden}/>
            <Menu />
            <SectionTitle picture={''} titleName={"Who We Are"} />
            <About />
            <SectionTitle picture={''} titleName={"FAQ"} />
            <Faqs />
            <SectionTitle picture={''} titleName={"Testimonials"} />
            <Testimonials />
            <Footer />
        </div>
    </>
}

export default MainContainer

























