import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import './Home.css';
import Modal from '../modal/Modal';
import { LocationContext } from '../../contexts/navigation/LocationContext'

function Home(){
    const navigate = useNavigate()
    const [ showModal, setShowModal ] = useState(false)

    const {setHomePageLanding} = useContext(LocationContext)

    function navigation(e){
        setHomePageLanding(true)
        navigate(`/${e.target.id}`)
    }
    
    return <>
    <Modal show={showModal} isDismissable={true} setShowModal={setShowModal}>
        <div className='homeAlertOutWrapper'>
            <div className='w-100 mx-auto my-auto homeAlertInnerWrapper'>
                <div>
                    The restaurant is located inside <b>Wright-Patterson Air Force Base</b>. You <b>MUST</b> have access to the base to order from us. 
                    <br/>
                    <br/>
                    <b>No outside delivery available</b>
                </div>
                
                <button 
                id="restaurant"
                type="button" class="btn btn-outline-danger"
                onClick={navigation}>Go to restaurant site</button>
            </div>    
        </div>
            
    </Modal>
     <div className="w-100 h-100 slideFromTopForPageEntrance">
            <div
                className="slideFromTopForPageEntrance wholeHeight wholeWidth backgroundPictureHome displayModeHome overflowHidden w-100"> 
                <div
                    className="wholeHeight_1 wholeWidth_1">
                        <div
                            className="backgroundPicture_2 height_2">
                        </div>
                </div>
            </div>
            <div className="buttonGroupHome slideFromTopForPageEntrance">
                <button id="foodtruck"
                className="buttonStyleHome "
                onClick={navigation}>Foodtruck</button>
                <button
                className="buttonStyleHome "
                onClick={()=>{setShowModal(true)}}>Restaurant</button>
            </div>
        </div>
         
        <br/>
        
    </>
}

export default Home