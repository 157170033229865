import React from "react";

import '../../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FaqTopic.css';
import '../../../Colors.css'


function FaqTopic({topic}){
    return <>
        <div 
        className="w-100 outerFaqThemeContainer">
            {topic}
        </div>
    </>
}
export default FaqTopic