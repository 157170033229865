import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import Helpers from "../../helpers/PublicHelper";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Colors.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarMod.css';

require('dotenv').config()

var apiUrl = ""
if(process.env.NODE_ENV===process.env.REACT_APP_DEV_MODE){
    apiUrl = process.env.REACT_APP_DEV_REST_API_SERVER
}else{
    apiUrl = process.env.REACT_APP_PROD_REST_API_SERVER
}


function CalendarMod({showCalendarMod,setShowCalendarMod,setShowSelectedEvent,setShowAlert}){   
    /* CALENDAR EVENTS ==========>  list of calendar events */
    const localizer = momentLocalizer(moment) // or globalizeLocalizer

    /** DOM MONIPULATION **/
    /** click events **/
    function removeDayClick(){
        setTimeout(() => {
            for (let i of document.getElementsByClassName('rbc-button-link')){
                var old_element = i;
                var new_element = old_element.cloneNode(true);
                old_element.parentNode.replaceChild(new_element, old_element);
            }
        }, 100);
    }
    function changeTextToLogo(){
        setTimeout(() => {
            for (let i of document.getElementsByClassName('rbc-event-content')){
                var old_element = i;
                var new_element = old_element.cloneNode(true);
                new_element.innerText = ""
                new_element.classList.add("logoColors")
                new_element.addEventListener('click',eventClickHandler)
                i.parentNode.replaceChild(new_element,old_element)
            }
        }, 100);
    }
    function callFunctions(){
        removeDayClick()
        changeTextToLogo()    
    }
    if(showCalendarMod){
        document.getElementsByClassName('rbc-toolbar')[0].children[2].style.display = 'none'
        callFunctions()
        //deleting eventListener from days
        for (let i of document.getElementsByClassName('rbc-btn-group')){
            for(let e of i.children){
                e.removeEventListener('click',callFunctions)
                e.addEventListener('click',callFunctions)
            }
        }
    }
    /** COMPONENT STATES **/
    // const [showSecondLevelModal,setShowSecondLevelModal] = useState({show:false,data:''})
    const [calendarData, setCalendarData] = useState({loaded:false,data:[]})
    const [events, setEvents] = useState([])
    const [builCalendarComponent, setBuildCalendarComponent] = useState(false)
    /** STATE SETTING FUNCTIONS **/
    function clearDataAndEvents(){
        setShowSelectedEvent({show:false,data:''})
        setEvents([])
    }
    function clearAllElements(){
        setShowCalendarMod(false)
        clearDataAndEvents()
        setBuildCalendarComponent(false)
    }
    function hideCalendarModalWindow(e){
        if(e.target.classList.contains('modal-outer-wrapperCalendar') ||
            e.target.classList.contains('calendarCloseButton')){
            clearAllElements()
        }
    }
    function showAlert(msg, alertClasses=null){
        setShowAlert({show:true,msg:msg,classes:alertClasses})
        clearAllElements()
        return
    }
    function loadData(data = []){
        const orderedData = data.sort((a,b) => a.eventOrder - b.eventOrder)  
        setCalendarData({loaded: orderedData.length>0 ? true : false ,data: orderedData})   
    }
    function loadEvents(events = []){   
        setEvents(events)   }
    function eventClickHandler(){
        setShowCalendarMod(false)
        setBuildCalendarComponent(false)
        setShowSelectedEvent({show:true,data:calendarData.data.filter(t => t.cookingDate.split(" ")[0] === this.title )})
    }
    /** FETCH DATA **/
    useEffect(()=>{
        if(!showCalendarMod){ return }
        clearDataAndEvents()
        fetch(`${apiUrl}/api/webpage/calendarEvents`,{
            headers: {
                'Content-Type':'application/json',
                'Access-Control-Request-Headers': 'Origin,Content-Type,Authorization'
            }   })
        .then(a1 => {return a1.json()})
        .then(response => {
            console.log(response)
            if(response.hasErrors){ 
                showAlert(`Sorry! Not possible to fetch data from the server! \u{1F605} `) 
            }else{
                if(response.msg.length===0){
                    showAlert(`Sorry! There aren't any upcoming events! \u{1F605}`,`alert-info`)
                }else {
                    loadData(response.msg)
                }
            }   })
        .catch(err => {
            console.log(err)
            showAlert(`Sorry! Not possible to fetch data from the server! \u{1F605} `)  })
    },[showCalendarMod])
    /** CREATE EVENTS TO POPULATE CALENDAR **/
    useEffect(()=>{
        if(!calendarData.loaded){ return }
        if(calendarData.data.length === 0) {
            showAlert("There aren't any upcoming events on the calendar for our foodtruck now. \u{1F629}")
        }
        var tempEvents = []
        var dateCheck
        calendarData.data.forEach(e => {
            let dateElements = (e.cookingDate).split(" ")[0].split('-')
            dateElements[1] = Helpers.getCorrectMonthForJavaScriptDate(dateElements[1])
            let date = new Date(...dateElements)
            if (dateCheck !== e.cookingDate.split(" ")[0]){
                console.log(dateCheck)
                console.log(e.cookingDate)
                tempEvents.push(
                {
                    title: `${e.cookingDate.split(" ")[0]}`,
                    start: date,
                    end: date,
                    allDay: true
                })
            }
            dateCheck = e.cookingDate.split(" ")[0]
        })
        console.log(tempEvents)
        loadEvents(tempEvents)
    },[calendarData])
    /** BUILD CALENDAR IF THERE IS AT LEAST ONE EVENT TO POPULATE THE CALENDAR **/
    useEffect(()=>{
        if(events.length===0){return}
        setBuildCalendarComponent(true)
    },[events])


    return <>
        <div 
            className="modal-outer-wrapperCalendar h-100 w-100" style={
                {visibility: showCalendarMod ? 'visible' : 'hidden',
                opacity: showCalendarMod ? 1 : 0}}
                onClick={hideCalendarModalWindow}>
            <div 
                className="modal-inner-wrapper">
                <div className="calendarLogoBackground"></div>
                <div 
                className={`calendarCloseButton`}
                onClick={hideCalendarModalWindow}>X</div>
                <div className="calendarWrapper h-100 w-100 row">
                    <div className="col-12 calendarColumns">
                        <div className={`calenarInneContent ${builCalendarComponent ? 'calenarInneContentShow' : ''}`}>
                            <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            />
                        </div>
                        <div className={`spinerInneContent ${builCalendarComponent ? 'spinerInneContentHide' : ''}`}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                            <p>Loading data...</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </>
}

export default CalendarMod