import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Colors.css'
import './Testimonials.css';

import "../../fonts/gothree.ttf"

import TestFile from "../../resources/files/testimonials.json";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.css'
//import required modules
import { Pagination, Navigation } from "swiper";


import Testimony from './components/Testimony'

function Testimonials(){
    // const [testimonials, setTestimonials] = useState(TestFile)
    const testimonials = TestFile
    return <>
    <div className="w-100 outerSwiperContainer">
        <Swiper
                key={`swiper_01`}
                slidesPerView={2}
                spaceBetween={10}
                slidesPerGroup={2}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {testimonials.map((t)=>(
                    t.testShowing ?
                    <>
                        <SwiperSlide key={`swiperSlide_${t.testId}`}>
                            <Testimony key={`swiperText_${t.testId}`} picture={t.testPictureUrl} name={t.testName} date={t.testPostDate} text={t.testPostText} link={t.testLink} />
                        </SwiperSlide>
                    </>
                    : ''
                ))}
            </Swiper>
    </div>
    </>
}

export default Testimonials