import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SectionTitle.css';
import '../../Colors.css'

function SectionTitle({picture, titleName,navBarHidden}){

    return <>
        <div
            className={`mx-auto w-100 whiteFontColor outerSectionTitleContainer ${navBarHidden && picture === 'menu' ? '' : ''} bordered`}> 
            <div className="alignTitleNameInCenter titleContainerFont textRight">{titleName}</div>
        </div>
        <div 
        className="w-50 borderGradientBottom marginBottomBottomBorder"></div>
    </>
}

export default SectionTitle