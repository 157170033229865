class Helpers {
    static returnMonth(monthIndex = 0){
        let months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        return months[monthIndex]
    }
    static checkNullString(string = ""){
        if(string==="Not informed" || 
            string === "" ||
            string === null){
                return ""
            }
        return string
    }
    static phoneNumberMask(phoneNumber){
        if(phoneNumber===null||phoneNumber===''||phoneNumber===undefined){
            return phoneNumber
        }
        var numbers = phoneNumber.replace(/\D/ig,'')
        if(numbers.length===10){
            return `(${numbers.substr(0,3)}) ${numbers.substr(3,3)}-${numbers.substr(6,4)}`
        }
        return numbers
    }
    static noPhoneNumberMask(phoneNumber){
        return phoneNumber.replace(/\D/ig,'')
    }
    static validateEmail(email){
        if(email.match(/^(?=.*[@])(?=.*[.])(\S*@\S*\.{1}\s*\.{0,1}\S*)/ig)){ return true }
        return false
    }
    static validatePhoneNumber(phoneNumber){
        if(Helpers.noPhoneNumberMask(phoneNumber).length===10){
            return true
        }
        return false
    }
    static getCorrectMonthForJavaScriptDate(mysqlMonth){
        if(mysqlMonth > 12) { return 0}
        return mysqlMonth -= 1

    }
}
export default Helpers 