import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Faqs.css';
import '../../Colors.css'

import Faq from "./components/Faq";
import FaqTopic from "./components/FaqTopic";

import FaqsFile from "../../resources/files/faqs.json";

function Faqs({picture, titleName,navBarHidden}){
    const faqs = FaqsFile
    var topic = ""
    function checkTopic(arrayTopic = ""){
        if(arrayTopic !== topic){
            topic = arrayTopic
            return true
        }
        return false
    }
    
    return <>
        <div
        className="faqsOuterContainer">
            <div 
            className="faqsInnerContainer">
                {faqs.map(f => (
                    f.faqShowing ? 
                        checkTopic(f.faqTopic) ?
                            <div key={`parenttopic_${f.faqId}`}>
                                <FaqTopic key={`topic_${f.faqId}`} topic={f.faqTopic} />
                                <Faq key={f.faqId.toString()} question={f.faqQuestion} answer={f.faqAnswer} />
                            </div>
                        :
                        <Faq key={f.faqId.toString()} question={f.faqQuestion} answer={f.faqAnswer} />
                    : ''
                ))
                }
            </div>
        </div>
    </>
}
export default Faqs