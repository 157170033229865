import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.css';
import '../../Colors.css'

import Row from "./components/Rows";

import MenuFile from "../../resources/files/menu.json";

function Menu(){
    const menuData = MenuFile
    return <>
        <div
            className="w-100 mx-auto my-auto menuOuterContainer backgroundPicturePlate"
            >
                <div className="w-100 backgroundBlack15alpha margin0 row">
                    {menuData.map(({dishPictureUrl,dishName,dishIngredients,dishPrice,dishId,dishShowing})=>(
                        dishShowing ? 
                        <Row key={dishId.toString()} picture={dishPictureUrl} dishName={dishName} id={dishId} price={dishPrice} ingredients={dishIngredients}/>     
                        : ''
                    ))}
                </div>
        </div>
    </>
}

export default Menu