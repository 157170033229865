import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import '../../Colors.css'

import "../../fonts/gothree.ttf"

function Footer(){
    
    return <>
        <div className="w-100 outerFooterContainer">
            <div className="itemsContainerRow row w-100">
                <div className="col-12 col-md-6 itemsContainerLogo"></div>
                <div className="col-12 col-md-6 row itemsContainerInformationRow">
                    <div className="col-12 itemsContainerContactUsText">Contact Us</div>
                    <div className="col-12 itemsContainerSocialMediaLogo">
                            <a className="itemContainerSocialMedia logoFacebook" href="https://www.facebook.com/roque.nl" target={"_blank"}><span hidden>Facebook</span></a>
                            <a className="itemContainerSocialMedia logoInstagram" href="https://www.instagram.com/kungfu_bbq/" target={"_blank"}><span hidden>Instagram</span></a>
                    </div>
                    <div className="col-12 footerTextStyle">email: <a href="mailto:kfubbq@kfubbq.com">kfubbq@kfubbq.com</a> </div>
                    <div className="col-12 footerTextStyle">mobile: <a href="tel:+19375039428">+1 (937) 503-9428</a></div>
                </div>
            </div>
            <p className="footerFontStyleFootnote footerFontStyleFootnotePaddingTop">KungFu BBQ © {new Date().getFullYear()}</p>
            <p className="footerFontStyleFootnote">Dayton, OH USA</p>
        </div>
    </>
}

export default Footer