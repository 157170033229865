import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NavContainer.css';
import '../../Colors.css'

import Logo from './logoBlack.png'

import "../../fonts/gothree.ttf"


function NavContainer({navBarHidden}){

    return <>
        <div
            className={`navBarMain navBgColorBlack mx-auto w-100 ${navBarHidden ? 'navbarHide' : 'navbarShow'} fixed-top`}> 
            <nav 
                className={`navbar navBarContainer w-100`}>
                <div 
                    className="whiteFontColor">
                    KungFu 
                </div>
                <a className="navbar-brand" href="/#" >
                    <img 
                        src={Logo} 
                        className="navbarLogo"
                        alt="Logo KungfuBBQ in black and white" />
                </a>
                <div 
                    className="whiteFontColor">
                    BBQ 
                </div>
            </nav>
        </div>
    </>
}

export default NavContainer