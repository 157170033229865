import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './About.css';
import '../../Colors.css'

function About({picture, titleName,navBarHidden}){
    
    return <>
    <div 
    className={`w-100 row outerGridContainer backgroundPictureLogo`}>
        {/* <div 
        className="col-12 col-md-6 usPhoto"></div> */}
        {/* <img
        className={`col-12 col-sm-6 aboutImgStyle`}
        src={Us}></img> */}
        <div 
        className={`col-12 aboutTextContainer `}>
            <p 
            className="aboutText">We are KungFu BBQ, a Family Owned and Operated Food Truck here in Dayton, Ohio. We are of humble beginnings, starting off by sharing our Family’s meals with others on Sundays, often from our Dad’s driveway or a nearby parking lot. We love cultural meals and offer these foods with our unique smoked interpretation, alongside our more traditionally styled American smoked fare. We had no idea that our Family’s food, our care for others, and our “Underground Vibe”  would, in a few short months, propel our "Waiting List" to over 5,000 hungry people!  We are extremely grateful that, person by person, we have been able to transform a hunger for good food into a craving for Real Food, Real People, and Real Memories!</p>
            <p className="aboutTextTitles">KungFu BBQ in the News:</p>
            <p className="aboutText"><a href="https://www.dayton.com/lifestyles/kungfu-bbq-a-revolution-in-food-and-business/5IG2JTIHOFAF7NDVDQYRWYV5PY/?fbclid=IwAR3UIgDcwdYzNt0fPCiFltWE3kcaQ5VLUObLqOcm2UNkszb0zOjEFoM220s" target={"_blank"}>KungFu BBQ a revolution in food and business</a> (dayton.com)</p>
            <p className="aboutText"><a href="https://www.dayton.com/what-to-know/popular-dayton-food-truck-opens-spot-at-wright-patterson-air-force-base/NW676PRS45DCPBU5H6Y6LVNGQQ/?fbclid=IwAR2eLLmeB0dMJHSKV1klFrtA8XvsQKFg4VedxT9X247zx2vcnwCYBGqyeKM" target={"_blank"}>Popular Dayton food truck opens spot at Wright-Patterson Air Force Base</a> (dayton.com)</p>
        </div>

    </div>
    </>
}

export default About