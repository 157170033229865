import React, { createContext, useState } from 'react';

export const LocationContext = createContext(null)


export function LocationContextProvider({ children }){
    const [landeOnHomePage, setHomePageLanding] = useState(false)

    return <LocationContext.Provider value={{landeOnHomePage, setHomePageLanding}}>
        { children }
    </LocationContext.Provider>
}