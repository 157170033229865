import React from "react";

import '../../reset.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Colors.css'
import './SelectedEvent.css';

import EventDetail from "./components/EventDetails";

function SelectedEvent({showSelectedEvent,setShowSelectedEvent,setShowCalendarMod}){   
    function hideSelectedEvent(){
        setShowSelectedEvent({show:false,data:''})
        setShowCalendarMod(true)
    }
    function dismissSelectedEventAndCalendar(e){
        if(e.target.classList.contains('secondModalOuterWrapper')){
            setShowSelectedEvent({show:false,data:''})
        }
    }
    const events = showSelectedEvent.data
    console.log(events)
    var results = []
    var index = 1
    
    for(const e of events){
        if(e.cookingStatusId > 3){ 
            results.push(
            <>
                <EventDetail event={e} index={index} onlyEvent={events.length===1 ? true : false}/>
            </>)
            index += 1
         }
    }
    
    return <>
        <div 
            className="secondModalOuterWrapper h-100 w-100" style={
                {visibility: showSelectedEvent.show ? 'visible' : 'hidden',
                opacity: showSelectedEvent.show ? 1 : 0}}
                onClick={dismissSelectedEventAndCalendar}
                >
                <div 
                className="secondModalInnerWrapper">
                    <div className="secondLevelModalLogoBackground"></div>
                    <div 
                    className={`secondLevelModalCloseButton`}
                    onClick={hideSelectedEvent}>X</div>
                </div>
                <div
                className="eventDataOuterWrapper">
                    {results}
                </div>
        </div>
    </>
}

export default SelectedEvent